<template>
  <div>
    <el-container>
      <el-header>
        <h3 style="text-align: center">课程信息</h3>
      </el-header>
      <el-container>
        <el-main>
          <el-row>
            <h4 style="text-align: left">课程信息</h4>
          </el-row>
          <el-row>
            <p>
              机器学习是一门研究在非特定编程条件下让计算机采取行动的学科。最近二十年，机器学习为我们带来了自动驾驶汽车、实用的语音识别、高效的网络搜索，让我们对人类基因的解读能力大大提高。当今机器学习技术已经非常普遍，您很可能在毫无察觉情况下每天使用几十次。许多研究者还认为机器学习是人工智能（AI）取得进展的最有效途径。在本课程中，您将学习最高效的机器学习技术，了解如何使用这些技术，并自己动手实践这些技术。更重要的是，您将不仅将学习理论知识，还将学习如何实践，如何快速使用强大的技术来解决新问题。最后，您将了解在硅谷企业如何在机器学习和AI领域进行创新。

              本课程将广泛介绍机器学习、数据挖掘和统计模式识别。相关主题包括：(i) 监督式学习（参数和非参数算法、支持向量机、核函数和神经网络）。(ii) 无监督学习（集群、降维、推荐系统和深度学习）。(iii)
              机器学习实例（偏见/方差理论；机器学习和AI领域的创新）。课程将引用很多案例和应用，您还需要学习如何在不同领域应用学习算法，例如智能机器人（感知和控制）、文本理解（网络搜索和垃圾邮件过滤）、计算机视觉、医学信息学、音频、数据库挖掘等领域。
            </p>
          </el-row>
          <el-row>
            <el-table
              :data="tableData"
              style="width: 80%;margin: 0 auto"
              :show-header="false"
              border
              :row-style="rowStyle">
              <el-table-column prop="one">
              </el-table-column>
              <el-table-column prop="two">
              </el-table-column>
            </el-table>
          </el-row>
          <el-row>
            <h4 style="text-align: left">
              课程大纲
            </h4>
          </el-row>
          <el-row>
            <el-collapse v-model="activeNames" style="width: 90%;margin: 0 auto;">
              <el-collapse-item title="第一章" name="1">
                <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>
                <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>
              </el-collapse-item>
              <el-collapse-item title="第二章" name="2">
                <div>控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；</div>
                <div>页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。</div>
              </el-collapse-item>
              <el-collapse-item title="第三章" name="3">
                <div>简化流程：设计简洁直观的操作流程；</div>
                <div>清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；</div>
                <div>帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。</div>
              </el-collapse-item>
              <el-collapse-item title="第四章" name="4">
                <div>用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；</div>
                <div>结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。</div>
              </el-collapse-item>
            </el-collapse>
          </el-row>
          <el-row>
            <h4 style="text-align: left">
              评分标准
            </h4>
          </el-row>
          <el-row>
            <p>课程总分=100分，其中包括单元测验（每章至少一次，45%）+ 期末考试（1次，35%）+ 课程讨论（20%）；</p>
          </el-row>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>

export default {
  name: 'CourseMessage',
  data () {
    return {
      dataForm: {
        a: 'xxx',
        b: 'xxx',
        c: 'xxx'
      },
      activeNames: ['1']
    }
  },
  computed: {
    // 因为数据用到了dataform中的数据，所以写在了computed中
    tableData () {
      return [
        {
          one: '如何通过',
          two: this.dataForm.a
        },
        {
          one: '用户评分',
          two: this.dataForm.b
        },
        {
          one: '前置课程',
          two: this.dataForm.c
        }
      ]
    }
  },
  methods: {
    // 自定义列背景色
    rowStyle ({
      row,
      rowIndex
    }) {
      if (rowIndex === 0) {
        return 'background:#f3f6fc;'
      } else {
        return 'background:#ffffff;'
      }
    }
  }
}
</script>
<style scoped>
.el-collapse >>> .el-collapse-item :hover {
  background-color: #f3f6fc !important;
}
</style>
